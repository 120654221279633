<template>
    <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
            <div class="row mb-2 container-header-title">
                <div class="col-sm-12 d-flex justify-content-between align-items-center">
                    <h4 class="mt-3 header-title">
                    <i :class="iconTitle"></i>
                        {{title}}
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: ['title', 'iconTitle']    
}
</script>

<style scoped>
.header-title {
    color: #0007;
}

.container-header-title {
    border-bottom: 1px solid #0001;
}
</style>